<template>
  <div ref="body">
    <custom-header active="service" :subActive="current"></custom-header>

    <div class="content-container">
      <h1 class="text-bold">Service & Business</h1>

      <div class="section">
        <p class="p4">
          For service inquiries, such as transportation, services, accommodation and other services, please contact: <a href="mailto:business-cooperation@szfa.com">luoliting@szfa.com</a>
        </p>
        <p class="p4">
          For business cooperation, such as docking procurement requirements, finding product information, seeking exhibitors and other cooperation, please contact: <a href="mailto:business-cooperation@szfa.com">business-cooperation@szfa.com</a>
        </p>
      </div>
      <!-- <div class="section">
        <ul class="theme-intro-box">
          <li>Hall 9, 10, 11, 13, 14, 15 - Shenzhen International Furniture Exhibition (original Chinese furniture from China’s top 200 brands)</li>
          <li>Hall 12 - Design Dome (a special curation connecting international and Chinese design)</li>
          <li>Hall 1, 3, 5, 7 - SMART+ Smart Home Exhibition (creating a new ecosystem for smart living across all scenarios, including smart control system, smart kitchen, smart sleep, smart bathroom, and smart lighting)</li>
          <li>Hall 8 - Lifestyle & Decor (representing contemporary young consumer lifestyle, art, and culture)</li>
          <li>Hall 4, 6 - Integrated Supply Chain (Home decor suppliers, online distributors)</li>
        </ul>
      </div> -->
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter,
    'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    title: 'Shenzhen Furniture Exhibition: Service & Business Inquiries | Contact Us', // set a title
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week' },
      { name: 'description', content: 'SIFEChina Features the Most Well-Established Brands, and Innovative Products. For Service & Business Inquiries, Visit this Page and Email us Now!  '},
    ],
  },
  data() {
    return {
      current: 'business',
    }
  },
  created() {

  },
  destroyed () {
    let videoElement = document.querySelector('#my-video');
    if (videoElement) {
      // 销毁 VIDEO 元素
      videoElement.pause(); // 停止播放
      videoElement.removeAttribute('src'); // 清空srcs属性来停止加载
      videoElement.load(); // 调用 load() 方法，以确保所有相关数据都被卸载。
      // 将 VIDEO 元素的事件监听器设为 null，以避免内存泄漏。
      videoElement.oncanplaythrough = null;
      videoElement.onerror = null;
      videoElement.remove();
      videoElement = null; // 最后将 VIDEO 元素赋值为 null，以释放其占用的内存。
    }
  },
  methods: {

  },
}
</script>

<style scoped lang="less">

// 移动端
@media screen and (max-width: 1023px) {
  .content-container {
    width: 100%;
    padding: 20px;
    
    h1 {
      font-size: 18px;
      text-align: center;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 15px;
      text-align: center;
      margin: 16px 0;
    }
    
    .section {
      margin: 10px 0;
    }
    .p4 {
      font-size: 13px;
      line-height: 2em;
      // text-align: justify;
      margin: 0 0 4px;
      
      a {
        color: #409eff;
      }
    }
    
    .theme-intro-box {
      font-size: 14px;
      line-height: 2em;
      
      li {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 8px;
      }
    }
  }
}

// pc
@media screen and (min-width: 1024px) {
  
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 190px;
    margin: 15px auto 20px;
    
    h1 {
      font-size: 8px;
      text-align: center;
    }
    h2 {
      width: 155px;
      margin: 0 auto;
      font-size: 6px;
      text-align: center;
    }
    .section {
      margin: 10px 0;
    }
    
    .p4 {
      font-size: 3.6px;
      line-height: 1.8em;
      text-align: justify;
      margin: 0 0 4px;
      
      a {
        color: #409eff;
      }
    }
    
    .theme-intro-box {
      font-size: 3.6px;
      line-height: 2em;
      
      li {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 8px;
      }
    }

  }
}

</style>